<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">
              メールアドレスとパスワードでサインイン
            </small>

            <v-text-field
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Email"
              v-model="email"
              :error-messages="emailErrors"
              @blur="$v.email.$touch()"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              type="password"
              placeholder="Password"
              v-model="password"
              :error-messages="passwordErrors"
              @blur="$v.password.$touch()"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#5e72e4"
              :loading="loading"
              @click="login"
              >Sign In</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-space-between mt-4">
        <a
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small>パスワードをお忘れですか？</small></a
        >
<!--        <a-->
<!--          href="javascript:;"-->
<!--          class="text-light text-decoration-none btn-hover no-default-hover"-->
<!--          ><small>新しいアカウントの作成</small></a-->
<!--        >-->
      </div>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import {email, maxLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      checkbox: false,
    };
  },
  mixins: [validationMixin],
  methods: {
    login() {
      this.loading = true;
      axios.post("/auth/login", {
        ActiveEmail: this.email,
        Password: this.password,
      }, {withCredentials: true}).then((res) => {
        this.setLoginUser(res.data);
        const companyId = this.getLoginUser().CompanyId;
        //TODO マジックナンバー、権限制御は改めて実装
        if (companyId === 1) {
          this.$router.push({ name: "Companies" });
        } else {
          // this.$router.push({ path: companyId + "/admin" });
          //TODO 機能初期リリースのため候補者画面のみ開示
          this.$router.push({ path: companyId + "/admin/candidates" });
        }
      }).catch(e => {
        console.log(e);
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarValidationError"]),
    ...mapActions("loginUser", ["setLoginUser"]),
    ...mapGetters("loginUser", ["getLoginUser"]),
  },
  validations() {
    return {
      email: {required, email, maxLength: maxLength(255)},
      password: {required},
    }
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.email.email && errors.push("メールアドレスの書式に誤りがあります");
      !this.$v.email.required && errors.push("入力必須項目です");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && !this.isUpdate && errors.push("入力必須項目です");
      return errors;
    },
  },
};
</script>
